<template>
	<div class="grey lighten-5 fill-height" v-if="siteReady" :style="style">
		<top-bar class="top-bar" />
		<div class="container-fluid content-pt">
			<router-view />
			<contact />
		</div>
		<footer-bar />
	</div>
</template>

<script>
import { initChatBot, initFacebookPixel } from '@/common/siteInit';
import { INIT_COMPANY } from '@/store/modules/company.module';
import FooterBar from '@/views/_shared/layout/enduser/Footer.vue';
import TopBar from '@/views/_shared/layout/enduser/TopBar.vue';
import Contact from '@/views/_shared/layout/listing/Contact.vue';
import VueGtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';
import { bootstrap, setOptions } from 'vue-gtag';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'ListingBase',
	components: {
		TopBar,
		FooterBar,
		Contact,
	},
	data() {
		return {
			initialized: false,
		};
	},
	computed: {
		...mapGetters([
			'getSite',
			'templateHexColor',
			'templateTextHexColor',
			'darkTemplate',
			'hasListing',
			'buttonBackColor',
			'buttonTextColor',
			'headerBackColor',
			'headerTextColor',
			'footerBackColor',
			'footerTextColor',
			'chatbot',
		]),
		isTopBarShow() {
			return this.getSite?.showContactInfoHeader;
		},
		style() {
			return {
				'--v-toolbar--content': this.isTopBarShow ? '120px' : '95px',
			};
		},
		siteReady() {
			return this.getSite && this.initialized;
		},
		gTagId() {
			return this.getSite.gTagId;
		},
		gTagManagerId() {
			return this.getSite.gTagManagerId;
		},
		pixel() {
			return this.getSite.pixel;
		},
	},
	mounted() {},
	created() {
		this.$nextTick(() => {
			this.initSite();
			initChatBot(this.chatbot);
			initFacebookPixel(this.pixel);
		});
	},
	methods: {
		...mapActions({
			initCompany: INIT_COMPANY,
		}),
		initGoogleAnalytics() {
			setOptions({
				config: { id: this.gTagId },
				pageView: {
					delay: 500,
				},
				appName: process.env.VUE_APP_VUE_APP_TITLE,
				pageTrackerScreenviewEnabled: true,
			});
			bootstrap().then(() => {
				console.log('Google Analytics is ready!');
			});
		},
		initGoogleTagManager() {
			if (!this.gTagManagerId || this.gTagManagerId === '') return;
			Vue.use(VueGtm, {
				id: this.gTagManagerId,
				enabled: true,
				debug: true,
				compatibility: true,
				loadScript: true,
				vueRouter: this.$router,
				ignoredViews: ['home'],
			});
		},
		initFavicon() {
			const faviconLink = document.querySelectorAll('link[rel="icon"]');
			faviconLink.forEach((element) => {
				element.setAttribute('href', this.getSite.favicon);
			});
		},
		initSite() {
			this.initCompany().then(() => {
				if (!this.hasListing) this.$router.push('/');
				else this.initialized = true;
			});
		},
	},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
* {
	font-family: 'Questrial', sans-serif;
}

.v-application {
	background-color: var(--v-background-base) !important;
	color: var(--v-lg-primary-text) !important;

	.error {
		&--text {
			color: var(--v-contrast-color) !important;
		}
	}
	.contrast-color {
		color: var(--v-contrast-color) !important;
	}
}

.content-pt {
	padding-top: var(--v-toolbar--content) !important;
	min-height: 700px;
}
.contact-container {
	padding: 5rem 1.5rem 6rem;
}
.top-bar {
	position: relative;
}
.favorite {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 2;

	.v-btn {
		box-shadow: 0px 0px 2px rgb(168, 168, 168) !important;
		background-color: rgba(255, 255, 255, 0.25) !important;
	}
}

.advantage-car {
	position: absolute;
	left: 15px;
	top: 15px;
	z-index: 5000;

	&--grid {
		left: 5px !important;
		top: 5px !important;
	}

	&--list {
		left: 20px !important;
		top: 20px !important;
	}
}
</style>
